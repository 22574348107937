<template>
  <span class="render">
    <router-link :to="{ name: 'clients', query: {  }, params: {  } }" tag="a">
      <clients-icon></clients-icon>
    </router-link>
    <router-link :to="{ name: 'developers', query: {  }, params: {  } }" tag="a">
      <profile-icon></profile-icon>
    </router-link>
  </span>
</template>

<script>
import clientsIcon from '../../../../public/img/icons/clipboard-solid.svg'
import profileIcon from '../../../../public/img/icons/user-alt-solid.svg'
export default {
  name: 'developerDNavigation',
  components: {
    clientsIcon,
    profileIcon
  }
}
</script>
